import { FullStory, init } from '@fullstory/browser'
import { type User } from 'src/types/api/generated'
import { FULLSTORY_ORG_ID } from 'src/variables'

const IS_FULLSTORY_ENABLED = Boolean(FULLSTORY_ORG_ID)

if (FULLSTORY_ORG_ID) {
  init({ orgId: FULLSTORY_ORG_ID })
}

const identifyUser = (user: User) => {
  if (!user || !IS_FULLSTORY_ENABLED) {
    return
  }

  const { id, email, is_admin: isAdmin, legal_name: displayName } = user

  FullStory('setIdentity', {
    uid: id.toString(),
    properties: {
      email,
      displayName,
      isAdmin,
    },
  })
}

// FS limit of 1000 per account
// see https://help.fullstory.com/hc/en-us/articles/360020623234-Client-API-Requirements#h_01H942JEBJ3B2601EJ5HYWPMNG
enum FullStoryEvent {
  START_QUICK_ACTIONS_ISSUANCE = 'Start quick actions issuance',
  SELECT_QUICK_ACTIONS_SINGLE_ISSUANCE = 'Select quick actions single issuance',
  SELECT_QUICK_ACTIONS_BULK_ISSUANCE = 'Select quick actions bulk issuance',
  SELECT_QUICK_ACTIONS_COMPLIANCE_CENTRE = 'Select quick actions compliance centre',
  BULK_ISSUANCE_IN_APP_SELECT_STAKEHOLDERS = 'Bulk issuance in-app select stakeholders',
  BULK_ISSUANCE_IN_APP_FILL_FORM = 'Bulk issuance in-app fill form',
  BULK_ISSUANCE_IN_APP_CONFIRM_ISSUANCES = 'Bulk issuance in-app confirm issuances',
  BULK_ISSUANCE_IN_APP_SUCCESS = 'Bulk issuance in-app success',
  BULK_ISSUANCE_IN_APP_FAILURE = 'Bulk issuance in-app failure',
  SECURITY_IMPORT_SUCCESS = 'Securities import success',
  SECURITY_IMPORT_FAILURE = 'Securities import failure',
  RSU_EDUCATION_CARD_VIEWED = 'RSU education card viewed',
  RSU_EDUCATION_LINK_CLICKED = 'RSU education link clicked',
  COMPANY_CLAIMED_TOKEN = 'Company claimed with token',
  COMPANY_CLAIMED_PORTFOLIO = 'Company claimed from portfolio',
  ONLINE_ISSUANCE_FILTER_BOX_CLICKED_DRAFTS = 'Online issuance filter box clicked drafts',
  ONLINE_ISSUANCE_FILTER_BOX_CLICKED_PENDING_GRANTHOLDER = 'Online issuance filter box clicked pending grantholder',
  ONLINE_ISSUANCE_FILTER_BOX_CLICKED_PENDING_ISSUER = 'Online issuance filter box clicked pending issuer',
  ONLINE_ISSUANCE_FILTER_BOX_CLICKED_FAILED = 'Online issuance filter box clicked failed',
  ONLINE_ISSUANCE_HAS_DRAFTS_TO_SEND_FOR_SIGNING = 'Online issuance has drafts to send for signing',
  ONLINE_ISSUANCE_HAS_GRANTHOLDERS_TO_NUDGE = 'Online issuance has grantholders to nudge',
  ONLINE_ISSUANCE_HAS_ISSUERS_TO_NUDGE = 'Online issuance has issuers to nudge',
  ONLINE_ISSUANCE_HAS_FAILED_GRANTS = 'Online issuance has failed grants',
  COMPANY_SETUP_BOOK_CALL_CLICKED = 'Book a call CTA in the implementation manager modal was clicked during setup',
  COMPANY_SETUP_SEND_EMAIL_CLICKED = 'Send email CTA in the implementation manager modal was clicked during setup',
  COMPANY_REGISTERS_PARTNER_UPSELL_LINK_CLICKED = 'Company registers partner upsell link clicked',
  COMPENSATION_PAGE_VIEWED_BY_USER_WITHOUT_ADDON = 'Compensation page viewed by user without add-on',
  COMPENSATION_CTA_CLICKED_BY_USER_WITHOUT_ADDON = 'Compensation CTA clicked by user without add-on',
  COMPLIANCE_STATUS = 'compliance status',
  COMPLIANCE_HAS_PENDING_ACTIONS = 'compliance has pending actions',
  COMPLIANCE_HAS_PENDING_ACTION_TYPE = 'compliance has pending action type',
  COMPLIANCE_CLICKED_PENDING_ACTION = 'compliance clicked pending action',
  COMPLIANCE_CLICKED_PENDING_ACTION_ACTIONS = 'compliance clicked pending action actions',
  COMPLIANCE_CREATED_MANUAL_EVENT = 'compliance created manual event',
  COMPLIANCE_HAS_ARCHIVEABLE_ACTION = 'compliance has archiveable action',
  COMPLIANCE_HOVERED_PENDING_ACTION = 'compliance hovered pending action',
  COMPLIANCE_CLICKED_ARCHIVE_ACTION = 'compliance clicked archive action',
  COMPLIANCE_CONFIRMED_ARCHIVE_ACTION = 'compliance confirmed archive action',
  COMPLIANCE_CONFIRMED_REMIND_ME_LATER_ACTION = 'compliance confirmed remind me later action',
  HMRC_FILINGS_SEEN_HOW_IT_WORKS = 'HMRC filings seen how it works',
  HMRC_FILINGS_CLICKED_HOW_IT_WORKS_LINK = 'HMRC filings clicked how it works link',
  HMRC_FILINGS_SEEN_EMPTY_REPORT_MODAL = 'HMRC filings seen empty report modal',
  HMRC_FILINGS_GENERATE_EMPTY_REPORT = 'HMRC filings generate empty report',
  HMRC_FILINGS_FILE_NIL_RETURN = 'HMRC filings file nil return',
  HMRC_FILINGS_CLICKED_CONTACT_SUPPORT_LINK = 'HMRC filings clicked contact support link',
  HMRC_FILINGS_CHANGE_INFORMATION = 'HMRC filings change information',
  HMRC_FILINGS_SEEN_MARK_AS_FILED_EMI_ANNUAL_RETURN = 'HMRC filings seen mark as filed EMI annual return',
  HMRC_FILINGS_SEEN_MARK_AS_FILED_EMI_NOTIFICATION = 'HMRC filings seen mark as filed EMI notification',
  HMRC_FILINGS_SEEN_MARK_AS_FILED_CSOP_ANNUAL_RETURN = 'HMRC filings seen mark as filed CSOP annual return',
  HMRC_FILINGS_SEEN_MARK_AS_FILED_OTHER_ERS_ANNUAL_RETURN = 'HMRC filings seen mark as filed Other ERS annual return',
  HMRC_FILINGS_SEEN_MARK_AS_FILED_EIS1 = 'HMRC filings seen mark as filed EIS1',
  HMRC_FILINGS_SEEN_MARK_AS_FILED_EIS_AA = 'HMRC filings seen mark as filed EIS AA',
  HMRC_FILINGS_MARK_AS_FILED = 'HMRC filings mark as filed',
  VALUATION_MISSING = 'valuation missing',
  EMPLOYEE_GRANT_REFRESHERS_BANNER_VIEWED = 'Employee grant refreshers banner viewed',
  EMPLOYEE_GRANT_REFRESHERS_BANNER_ACTION_BUTTON_CLICKED = 'Employee grant refreshers banner action button clicked',
  EMPLOYEE_GRANT_REFRESHERS_BANNER_DISMISSED = 'Employee grant refreshers banner dismissed',
  EMPLOYEE_GRANT_REFRESHERS_REPORT_REQUESTED = `Employees' vesting overview report requested`,
  EXERCISE_FUNNEL_ACTION = 'exercise funnel action',
  ISSUED_EMI_GRANT = 'issued-emi-grant',
  ISSUED_TO_US_STAKEHOLDER = 'issued-to-us-stakeholder',
  CONFIRM_ADMIN_DETAILS_MODAL_VIEWED = 'Confirm admin details modal viewed',
  CONFIRM_ADMIN_DETAILS_MODAL_CONFIRM_BUTTON_CLICKED = 'Confirm admin details modal confirm button clicked',
  DRAFTS_TOGGLE_USED_VIA_SIDE_MENU = 'Drafts toggle used via the side menu',
  DRAFTS_TOGGLE_USED_VIA_DRAFTS_PAGE = 'Drafts toggle used via the drafts page',
  VIEW_DRAFTS_CLICKED = 'View drafts button on drafts page clicked',
  // Favourite
  FAVOURITE_ADD_FROM_SIDE_NAV = 'Favourite add from side nav',
  FAVOURITE_REMOVE_FROM_SIDE_NAV = 'Favourite remove from side nav',
  FAVOURITE_ADD_FROM_PAGE = 'Favourite add from page',
  FAVOURITE_REMOVE_FROM_PAGE = 'Favourite remove from page',
  FAVOURITE_LINK_CLICKED = 'Favourite link clicked',

  // Resolutions
  RESOLUTION_DOCUMENT_UPLOAD = 'Resolution document upload',
  RESOLUTION_CATEGORY_PREFILLED = 'Resolution category prefilled',
  RESOLUTION_CATEGORY_CHANGED = 'Resolution category changed',
  RESOLUTION_DATE_ADOPTED_PREFILLED = 'Resolution adoption date prefilled',
  RESOLUTION_DATE_ADOPTED_CHANGED = 'Resolution adoption date changed',
  RESOLUTION_SEARCHED = 'Resolution searched',

  CS01_CONFIRMATION_STATEMENT_STEP = 'CS01 confirmation statement step reached',
  CS01_REVIEW_COMPANY_INFORMATION_STEP = 'CS01 review company information step reached',
  CS01_REVIEW_STATEMENT_OF_CAPITAL_STEP = 'CS01 review statement of capital step reached',
  CS01_REVIEW_SHAREHOLDERS_STEP = 'CS01 review shareholders step reached',
  CS01_REVIEW_PEOPLE_REGISTER_STEP = 'CS01 review people register step reached',

  CS01_DISCREPANT_REGISTERED_OFFICE = 'CS01 registered office discrepancy shown',
  CS01_DISCREPANT_SIC_CODE = 'CS01 SIC codes discrepancy shown',
  CS01_DISCREPANT_SHARE_CAPITAL = 'CS01 share capital discrepancy shown',
  CS01_DISCREPANT_SHARE_CLASSES = 'CS01 share classes discrepancy shown',
  CS01_DISCREPANT_PSCS = 'CS01 PSCs discrepancy shown',
  CS01_DISCREPANT_DIRECTORS = 'CS01 directors discrepancy shown',
  CS01_DISCREPANT_SECRETARIES = 'CS01 secretaries discrepancy shown',
  CS01_DISCREPANT_PSC_STATEMENT = 'CS01 PSC statement discrepancy shown',
  CS01_DISCREPANT_SHAREHOLDERS = 'CS01 shareholders discrepancy shown',

  CS01_DISCREPANT_COMPANY_INFORMATION_RESOLVED = 'CS01 company information discrepancy resolved',
  CS01_DISCREPANT_STATEMENT_OF_CAPITAL_RESOLVED = 'CS01 statement of capital discrepancy resolved',
  CS01_DISCREPANT_PEOPLE_REGISTER_RESOLVED = 'CS01 people register discrepancy resolved',

  CS01_FILING_GENERATED = 'CS01 filing generated',
  CS01_FILED_TO_COMPANIES_HOUSE_WITH_DISCREPANCIES = 'CS01 filed to Companies House with discrepancies',
  CS01_FILED_TO_COMPANIES_HOUSE_WITHOUT_DISCREPANCIES = 'CS01 filed to Companies House without discrepancies',
  CS01_FILED_TO_COMPANIES_HOUSE_SUCCESSFUL = 'CS01 filed to Companies House was successful',

  EQUITY_CALCULATOR_SEEN = 'Equity calculator seen',
  EQUITY_CALCULATOR_INTERACTION = 'Equity calculator interaction',
  GET_EMI_OPTION_PLAN = 'Get emi option plan',

  DISPLAY_CURRENCY_CHANGE = 'Display currency changed from top navigation menu',
}

// FS limit of 5000 combinations of name+property
enum FullStoryEventProperty {
  name = 'name',
  template_type_str = 'template_type_str',
  company_id = 'company_id',
  type = 'type',
  status = 'status',
  valuation_missing = 'valuation_missing',
  count = 'count',
}

const trackEvent = (eventName: FullStoryEvent, eventProperties: Partial<Record<FullStoryEventProperty, any>> = {}) => {
  if (IS_FULLSTORY_ENABLED) {
    FullStory('trackEvent', { name: eventName, properties: eventProperties })
  }
}

export { identifyUser, trackEvent, FullStoryEvent, FullStoryEventProperty }
