import { palette } from './palette'

const basicSwatches = {
  primaryLightest: '#595959',
  primaryLighter: '#474747',
  primaryLight: '#2E2E2E',
  primary: '#000000',

  secondary: '#FF7C55',
  secondaryDark: '#D96948',
  secondaryDarker: '#B8593D',
  secondaryDarkest: '#6B3524',

  accent: '#0057AF',
  accentDark: '#004E9E',
  accentDarker: '#00468C',
  accentDarkest: '#002245',

  black: '#000000',
  white: '#FFFFFF',
  cream: '#FBF8F7',

  transparent: '#12141708',
}

const visualSwatches = {
  grayLightest: palette.gray10,
  grayLighter: palette.gray30,
  grayLight: palette.gray40,
  gray: palette.gray60,
  grayDark: palette.gray70,
  grayDarker: palette.gray80,
  grayDarkest: palette.gray100,

  purpleLightest: palette.purple10,
  purpleLighter: palette.purple30,
  purpleLight: palette.purple40,
  purple: palette.purple60,
  purpleDark: palette.purple70,
  purpleDarker: palette.purple80,
  purpleDarkest: palette.purple100,

  blueLightest: palette.blue10,
  blueLighter: palette.blue30,
  blueLight: palette.blue40,
  blue: palette.blue60,
  blueDark: palette.blue70,
  blueDarker: palette.blue80,
  blueDarkest: palette.blue100,

  turquoiseLightest: palette.turquoise10,
  turquoiseLighter: palette.turquoise30,
  turquoiseLight: palette.turquoise40,
  turquoise: palette.turquoise60,
  turquoiseDark: palette.turquoise70,
  turquoiseDarker: palette.turquoise80,
  turquoiseDarkest: palette.turquoise100,

  brownLightest: palette.brown10,
  brownLighter: palette.brown30,
  brownLight: palette.brown40,
  brown: palette.brown60,
  brownDark: palette.brown70,
  brownDarker: palette.brown80,
  brownDarkest: palette.brown100,

  limeLightest: palette.lime10,
  limeLighter: palette.lime30,
  limeLight: palette.lime40,
  lime: palette.lime60,
  limeDark: palette.lime70,
  limeDarker: palette.lime80,
  limeDarkest: palette.lime100,

  greenLightest: palette.green10,
  greenLighter: palette.green30,
  greenLight: palette.green40,
  green: palette.green60,
  greenDark: palette.green70,
  greenDarker: palette.green80,
  greenDarkest: palette.green100,

  orangeLightest: palette.orange10,
  orangeLighter: palette.orange30,
  orangeLight: palette.orange40,
  orange: palette.orange60,
  orangeDark: palette.orange70,
  orangeDarker: palette.orange80,
  orangeDarkest: palette.orange100,

  yellowLightest: palette.yellow10,
  yellowLighter: palette.yellow30,
  yellowLight: palette.yellow40,
  yellow: palette.yellow60,
  yellowDark: palette.yellow70,
  yellowDarker: palette.yellow80,
  yellowDarkest: palette.yellow100,

  redLightest: palette.red10,
  redLighter: palette.red30,
  redLight: palette.red40,
  red: palette.red60,
  redDark: palette.red70,
  redDarker: palette.red80,
  redDarkest: palette.red100,
}

const statusSwatches = {
  successLightest: palette.green10,
  successLighter: palette.green30,
  successLight: palette.green40,
  success: palette.green60,
  successDark: palette.green70,
  successDarker: palette.green80,
  successDarkest: palette.green100,

  warningLightest: palette.yellow10,
  warningLighter: palette.yellow30,
  warningLight: palette.yellow40,
  warning: palette.yellow60,
  warningDark: palette.yellow70,
  warningDarker: palette.yellow80,
  warningDarkest: palette.yellow100,

  errorLightest: palette.red10,
  errorLighter: palette.red30,
  errorLight: palette.red40,
  error: palette.red60,
  errorDark: palette.red70,
  errorDarker: palette.red80,
  errorDarkest: palette.red100,

  informationLightest: palette.blue10,
  informationLighter: palette.blue30,
  informationLight: palette.blue40,
  information: palette.blue60,
  informationDark: palette.blue70,
  informationDarker: palette.blue80,
  informationDarkest: palette.blue100,
}

export { basicSwatches, visualSwatches, statusSwatches }
