import { TypographyWeight } from '@/camo/atoms/Typography'
import { HOVER_TOOLTIP_SETTINGS, Tooltip } from '@/camo/hoc/Tooltip'
import { useBreakpoint } from '@/camo/hooks'
import { ConditionalWrapper } from '@/camo/layouts/ConditionalWrapper'
import { getNodeText } from '@/camo/utils'
import { expandShorthand } from '@/camo/utils/expand-shorthand'
import { useStyletron } from '@/camo/utils/theme'
import { Button as BaseButton, SHAPE } from 'baseui/button'
import { ButtonKind, ButtonSize } from './enums'
import { type ButtonProps } from './types'
import { useExtendedButtonKind } from './use-extended-button-kind'

const Button = ({
  kind: kindProp = ButtonKind.PRIMARY,
  children,
  size = ButtonSize.MEDIUM,
  tooltip,
  tooltipOverrides = { ...HOVER_TOOLTIP_SETTINGS, showArrow: false },
  fullWidth = false,
  tabIndex,
  isActive,
  ...props
}: ButtonProps) => {
  const [, theme] = useStyletron()
  const { getResponsiveValue } = useBreakpoint()

  const kind = props.isLoading ? ButtonKind.PRIMARY : kindProp

  const { overrides, baseKind } = useExtendedButtonKind({
    isActive,
    kind,
    baseStyle: expandShorthand({
      padding: '8px 16px',
      minHeight: size === ButtonSize.SMALL ? '24px' : '40px',
      maxHeight: size === ButtonSize.SMALL ? '24px' : '40px',
      whiteSpace: 'nowrap',
      ...(getResponsiveValue(fullWidth) ? { width: '100%' } : { width: 'fit-content' }),
      ...(size === ButtonSize.SMALL ? { ...theme.typography.LabelXSmall, padding: '4px 8px' } : undefined),
      fontWeight: TypographyWeight.MEDIUM,
      fontFamily: theme.typography.primaryFontFamily,
    }),
    size,
  })
  return (
    <ConditionalWrapper
      condition={Boolean(props.disabled)}
      wrapper={(children) => {
        return (
          <Tooltip content={tooltip} {...tooltipOverrides}>
            <div>{children}</div>
          </Tooltip>
        )
      }}
    >
      <BaseButton
        aria-label={getNodeText(children)}
        overrides={overrides}
        {...props}
        size="default"
        kind={baseKind}
        shape={SHAPE.pill}
        tabIndex={tabIndex}
      >
        {children}
      </BaseButton>
    </ConditionalWrapper>
  )
}

Button.displayName = 'Button'

export { Button }
